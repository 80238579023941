import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Layout from "../components/layout"
import SEO from "../components/seo"
import style from "./singlePhotoTemplate.module.scss"

export default ({ pageContext }) => {
    const singlePhoto = pageContext.imgData
    const { next, prev } = pageContext
    return (
        <Layout sectionClass={style.photoPage}>
            <SEO title={`Photo ${singlePhoto.name}`} ogImage={`${singlePhoto.childImageSharp.fluid.src}`} />
            <section className={style.imgBlock}>
                <figure className={style.imgBlock__figure}>
                    <Img fluid={{ ...singlePhoto.childImageSharp.fluid, aspectRatio: 3/2, }} />
                    <figcaption className={`u-mgb--1 ${style.imgBlock__caption}`}><small>photo { singlePhoto.base }</small></figcaption>
                </figure>
                <nav className={style.navigation}>
                    { prev
                        ?
                        <AniLink
                            fade
                            to={`photos/${prev.name}`}
                            duration={0.6}
                            className={`${style.navigation__link} ${style.navigation__linkLeft}`}
                        >
                            Previous
                            <div className={style.navigation__caption}><small>{`${prev.name}.jpg`}</small></div>
                        </AniLink>
                        :
                        <div className={`${style.navigation__link} ${style.navigation__linkIsEmpty}`} />
                    }
                    <a className={`${style.navigation__link} ${style.navigation__linkDl}`} href={singlePhoto.publicURL} download>Download<span className="u-hidden--sm"> printable version</span></a>
                    { next
                        ?
                        <AniLink
                            fade
                            to={`photos/${next.name}`}
                            duration={0.6}
                            className={`${style.navigation__link} ${style.navigation__linkRight}`}
                        >
                            Next
                            <div className={style.navigation__caption}><small>{`${next.name}.jpg`}</small></div>
                        </AniLink>
                        :
                        <div className={`${style.navigation__link} ${style.navigation__linkIsEmpty}`} />
                    }
                </nav>
            </section>
        </Layout>
    )
}

export const query = graphql`
    query photoQuery(
        $id: String
        $base: String
        $publicURL: String
    ) {
        file(
            id: { eq: $id } 
            base: { eq: $base } 
            publicURL: { eq: $publicURL }
        ) {
            id
            base
            name
            publicURL
            childImageSharp {
                fluid(maxWidth: 1920, fit: COVER, cropFocus: ATTENTION) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
